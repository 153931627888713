import { datadogRum } from '@datadog/browser-rum'
import Config from '@/config'

interface Dict {
  [key: string]: any
}

const DD_APPLICATION_ID = Config.DD_APPLICATION_ID
const DD_CLIENT_TOKEN = Config.DD_CLIENT_TOKEN

const wrapMp = (fn: () => void) => {
  try {
    if (DD_APPLICATION_ID && DD_CLIENT_TOKEN) {
      fn()
    }
  } catch (e) {
    console.error(e)
  }
}

export const mpActions = {
  identify: (id: string) => {
    wrapMp(() =>
      datadogRum.setUser({
        id: id,
      })
    )
  },
  track: (name: string, props?: Dict) => {
    wrapMp(() => datadogRum.addAction(name, props))
  },
  register: (props: Dict) => {
    wrapMp(() => datadogRum.setGlobalContext(props))
  },
  people: {
    set: (props: Dict) => {
      wrapMp(() => {
        Object.keys(props).forEach((key) => {
          datadogRum.setUserProperty(key, props[key])
        })
      })
    },
  },
}
