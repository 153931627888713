export enum DELIVERY_INSTRUCTIONS {
  SAFE_PLACE = 'Leave at a safe place',
  NEIGHBOUR = 'Leave with neighbour or concierge',
  ADDRESS = 'Change delivery address',
  PHONE = 'Call before delivery',
  DOORBELL = 'Ring doorbell',
}

export enum BREAKPOINTS {
  XL = '1200px',
  LG = '992px',
  MD = '768px',
  SM = '576px',
  XS = '480px',
}

export enum STATUSES {
  INVALID = 'invalid',
  SUCCESS = 'success',
  ERROR = 'error',
  INELIGIBLE = 'ineligible',
  LATE = 'late',
  FAILURE = 'failure',
  RTS = 'rts',
  SUCCESS_ADDRESS = 'success_address',
}

export enum GRANULAR_STATUS {
  COMPLETED = 'Completed',
  ON_HOLD = 'On Hold',
  CANCELLED = 'Cancelled',
  RTS = 'Returned to Sender',
  ON_DELIVERY = 'On Vehicle for Delivery',
}

export enum ORDER_INSTRUCTION_TYPE {
  SAFE_PLACE = 'LEAVE_AT_SAFE_PLACE',
  NEIGHBOUR = 'LEAVE_WITH_NEIGHBOR',
  ADDRESS = 'CHANGE_DELIVERY_ADDRESS',
  PHONE = 'GIVE_A_CALL',
  DOORBELL = 'RING_DOORBELL',
}

export enum SOCIAL_MEDIA_SITE {
  FACEBOOK = 'Facebook Messenger',
  VIBER = 'Viber',
  TELEGRAM = 'Telegram',
  LINE = 'LINE',
  ZALO = 'Zalo',
  WHATSAPP = 'Whatsapp',
  WIDGET = 'Widget',
  DASH = 'Dash Mobile',
  OTHERS = 'Others',
}

export enum COLLECTION_METHODS {
  PICKUP = 'Pick up from your address',
  DROPOFF = 'Drop off at Ninja Point',
}

export enum PLAN {
  PICKUP_TITLE = 'PICKUP',
  DROPOFF_TITLE = 'DROPOFF',
}

export enum INTERNATIONAL_MY_PLAN {
  PICKUP_TITLE = 'PICKUP_INTL_MY',
  DROPOFF_TITLE = 'DROPOFF_INTL_MY',
}

export enum INTERNATIONAL_PH_PLAN {
  PICKUP_TITLE = 'PICKUP_INTL_PH',
  DROPOFF_TITLE = 'DROPOFF_INTL_PH',
}

export enum COUNTRY_CODES {
  SINGAPORE = 'sg',
  MALAYSIA = 'my',
  INDONESIA = 'id',
  THAILAND = 'th',
  VIETNAM = 'vn',
  PHILIPPINES = 'ph',
}

export enum PAYMENT_STATUSES {
  PENDING = 'PENDING_PAYMENT',
  SUCCESS = 'PAYMENT_SUCCESS',
  FAILURE = 'PAYMENT_FAILED',
}

export const TID_ERROR_STATUSES = {
  USED: 'USED_TID',
  INVALID: 'INVALID_TID', // tid for given hash does not exist
} as const

export type TID_ERROR_STATUSES_TYPE =
  (typeof TID_ERROR_STATUSES)[keyof typeof TID_ERROR_STATUSES]

export const C2C_SUPPORT = {
  EMAIL: 'shippercare_sg@ninjavan.co',
  PHONE: '+65 9643 0254',
  WHATSAPP_LINK: 'https://wa.me/message/56GAVV32GJBAF1',
} as const

export const C2C_SUPPORT_MY = {
  EMAIL: 'shippercare_my@ninjavan.co',
} as const

export const BA_STATUSES = {
  END_STATE: 'END_STATE', // order status is completed or RTS or cancelled
  RTS_TRIGGERED: 'RTS_TRIGGERED', // order has been scheduled to RTS
  RETURN_ORDER: 'RETURN_ORDER', // order is a return order
  UPDATED_BEFORE: 'UPDATED_BEFORE', // user has submitted this form before
  UPDATE_UNSUCCESSFUL: 'UPDATE_UNSUCCESSFUL',
  UPDATE_SUCCESSFUL: 'UPDATE_SUCCESSFUL',
} as const

export enum PAYMENT_METHOD {
  CREDIT_CARD = 'CC',
  APPLE_PAY = 'APPLEPAY',
  GOOGLE_PAY = 'GOOGLEPAY',
  PAYMENT_URL = 'PAYMENT_URL',
}

export enum PAYMENT_PROVIDER {
  PP_2C2P = 'PP_2C2P',
}
