import React, { useEffect, useMemo, useState } from 'react'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { DatadogInitializer } from '@/components/Datadog'
import colors from '@/utils/constants/colors'
import TagManager from 'react-gtm-module'
import { GTM_ID } from '@/utils/constants/gtm'
import { useRouter } from 'next/router'
import { GA_EVENT_NAME } from '@/utils/constants/trackGA'
import { trackGAEvent } from '@/utils/functions/trackGA'
import 'antd/dist/antd.css'
import { IntlProvider } from 'react-intl'
import INDONESIA from '@/translations/id_ID.json'
import ENGLISH from '@/translations/en.json'
import THAILAND from '@/translations/th.json'
import '@nv/react-akira/src/app.css'
import { initialiseFaro } from '@/faro'
import '@/styles/index.css'
import { clarity } from 'react-microsoft-clarity'
import { CLARITY_ID } from '@/utils/constants/clarity'
import { SupportedLocales } from '@/types/commons/constants'
import { SUPPORTED_LOCALES } from '@/utils/constants/locales'

const theme = {}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Lato', sans-serif;
    color: ${colors.TEXT.PAGE_DEFAULT}
  }
`

export default function ConsigneePortalApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const [locale, setLocale] = useState('id') // default locale
  const [messages, setMessages] = useState<{ [key: string]: string }>(INDONESIA) // default language
  const defaultENRoutes = useMemo(() => ['/live-tracker'], []) // all routes that want to use the default locale as EN.

  useEffect(() => {
    initialiseFaro()
    TagManager.initialize({ gtmId: GTM_ID })

    // microsoft clarity init
    clarity.init(CLARITY_ID)
  }, [])

  useEffect(() => {
    if (router.query.locale) {
      // this additional logic is to prevent errors when the user inputs a random query string for the locale
      if (SUPPORTED_LOCALES.includes(router.query.locale as SupportedLocales)) {
        setLocale(router.query.locale.toString())
      } else {
        if (defaultENRoutes.includes(router.pathname)) {
          setLocale('en')
        } else {
          setLocale('id')
        }
      }
    } else if (defaultENRoutes.includes(router.pathname)) {
      // set default locale as en for specific routes
      setLocale('en')
    } else if (locale != navigator.language.substring(0, 2)) {
      setLocale(navigator.language.substring(0, 2))
    }
  }, [locale, router.query.locale, router.pathname, defaultENRoutes])

  useEffect(() => {
    if (locale === 'id') {
      setMessages(INDONESIA)
    } else if (locale === 'en') {
      setMessages(ENGLISH)
    } else if (locale === 'th') {
      setMessages(THAILAND)
    } else {
      setMessages(INDONESIA)
    }
  }, [locale])

  useEffect(() => {
    trackGAEvent(GA_EVENT_NAME.PAGE_VIEW)
  }, [router.pathname])

  return (
    <>
      <Head>
        <title>Portal - Ninja Van</title>
        <link rel='icon' href={`${process.env.basePath}/ninja-favicon.ico`} />
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='preconnect' href='https://fonts.gstatic.com' />
        <link
          href='https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap'
          rel='stylesheet'
        />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
          charSet='UTF-8'
        />
      </Head>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <DatadogInitializer>
          <IntlProvider locale={locale} messages={messages} defaultLocale='en'>
            <Component {...pageProps} />
          </IntlProvider>
        </DatadogInitializer>
      </ThemeProvider>
    </>
  )
}
