import React, { useState, useEffect } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import Config from '@/config'
import { useDatadogGeneralSuperPropertiesTracker } from '@/hooks/api/useDatadog'

const ENV = Config.ENVIRONMENT
const DD_APPLICATION_ID = Config.DD_APPLICATION_ID
const DD_CLIENT_TOKEN = Config.DD_CLIENT_TOKEN
const DD_SITE = (Config.DD_SITE || 'datadoghq.com') as any
const DD_SERVICE = Config.DD_SERVICE || ''
const DD_RUM_SAMPLING_RATE = !isNaN(Number(Config.DD_RUM_SAMPLING_RATE))
  ? parseInt(Config.DD_RUM_SAMPLING_RATE)
  : 5
const DD_RUM_REPLAY_SAMPLING_RATE = !isNaN(
  Number(Config.DD_RUM_REPLAY_SAMPLING_RATE)
)
  ? parseInt(Config.DD_RUM_REPLAY_SAMPLING_RATE)
  : 20

type DatadogInitializerProps = {
  children: JSX.Element
}

export const DatadogInitializer = ({ children }: DatadogInitializerProps) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false)

  useEffect(() => {
    if (DD_APPLICATION_ID && DD_CLIENT_TOKEN) {
      datadogRum.init({
        applicationId: DD_APPLICATION_ID,
        clientToken: DD_CLIENT_TOKEN,
        site: DD_SITE,
        service: DD_SERVICE,
        env: ENV,
        sessionSampleRate: DD_RUM_SAMPLING_RATE,
        sessionReplaySampleRate: DD_RUM_REPLAY_SAMPLING_RATE,
        defaultPrivacyLevel: 'mask-user-input',
        useSecureSessionCookie: true,
        usePartitionedCrossSiteSessionCookie: true,
        allowFallbackToLocalStorage: true,
        enableExperimentalFeatures: ['feature_flags'],
      })

      setIsInitialized(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isInitialized ? (
    <DatadogGeneralEventsWrapper>{children}</DatadogGeneralEventsWrapper>
  ) : (
    children
  )
}

export const DatadogGeneralEventsWrapper = ({
  children,
}: DatadogInitializerProps) => {
  useDatadogGeneralSuperPropertiesTracker()
  return children
}
